import Image from 'next/image';
import { FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import { Button } from 'components/UIKit/Button';
import { H1, H4, T } from 'components/UIKit/Typography';

import s from './Page404.module.scss';

export const Page404: FC = () => {
  return (
    <div className={s.wrapper}>
      <ALink href="/">
        <Image
          src="/icons/logo.svg"
          className={s.content}
          width={219}
          height={21}
          alt=""
        />
      </ALink>

      <H1 className={s.errorName}>Ошибка 404</H1>

      <Image src="/landing/errors/404.svg" width={180} height={119} alt="404" />

      <H4 className={s.title}>
        <T>К сожалению, что-то пошло не так…</T>
      </H4>

      <p className={s.message}>
        <T>
          Возможно, вы перешли по недействительной ссылке или страница была
          удалена.
        </T>
      </p>

      <Button href="/" className={s.button} look="primary">
        На главную
      </Button>
    </div>
  );
};
